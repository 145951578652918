import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminItem from "./AdminItem";
import AdminDialogueBox from "./AdminDialogueBox";
import "../Styles/Admin.css";
import PowerBiLinkedTab from "./PowerBiLinkedTab";
import AdminSelectComponent from "./AdminSelectComponent";
import protectedAxiosInstance from "./helpers/api";

interface props {
    editModeTab: string;
  setEditMode: (value: string) => void;
}

const AdminHorizontalTile: React.FC<props> = ({editModeTab, setEditMode }) => {
  const [pageName, setPageName] = useState<string>("");
  const [parentContainer, setParentContainer] = useState<string>("");
  const [navList, setNavList] = useState<any>([]);
  const [editableNavList, setEditableNavList] = useState<any>([]);
  const [horizontalNavList, setHorizontalNavList] = useState<any>();
  const [editableHorizontalNavList, setEditableHorizontalNavList] =
    useState<any>({});
  const [secondLevelData, setSecondLevelData] = useState<any>([]);
  const [thirdLevelData, setThirdLevelData] = useState<any>([]);
  const [report, setReport] = useState<string>("");
  const [powerBiLinks, setPowerBiLinks] = useState<any>([]);
  const [showdialog, setShowDialog] = useState<boolean>(false);
  const [firstSelectValue, setFirstSelectValue] = useState<string>("select");
  const [secondSelectValue, setSecondSelectValue] = useState<string>("select");
  const [thirdSelectValue, setThirdSelectValue] = useState<string>("select");
  const [inEditMode, setInEditMode] = useState<any>([]);
  const [emptyObjList, setEmptyObjList] = useState<any>([]);
  // https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/7334cd75-166f-4e43-9eee-ac99081c89cb/ReportSection3ca4a57200502c6d92eb?experience=power-bi

  useEffect(() => {
    console.log("horizontal Nav List ", horizontalNavList);
  }, [horizontalNavList]);

  const updateHorizontalNavList = async () => {
    let a = editableHorizontalNavList;
    let b = horizontalNavList;
    let temp = Object.keys(a);
    console.log("Updated Horizontal Nav List ", horizontalNavList);

    let newPowerBiLinks: any = [],
      updatedPowerBiLinks: any = [],
      deletedPowerBiLinks: any = [];

    a[report]?.forEach((element: any, index: number) => {
      // check if horizontalNavList has an element with same tileId
      const tile =
        horizontalNavList[report] != undefined &&
        horizontalNavList[report]?.filter(
          (item: any) => item.tileId === element.tileId
        );
      if (tile && tile.length > 0) {
        // check if powerBiLink is different

        if (tile[0].powerBiLink !== element.powerBiLink) {
          // update powerBiLink
          console.log(
            "Tile ",
            element.tileId,
            tile[0].powerBiLink,
            element.powerBiLink
          );
          updatedPowerBiLinks.push(
            createEmbedURL(element.powerBiLink, element.tileId)
          );
        }
      } else {
        // add new powerBiLink
        newPowerBiLinks.push(
          createEmbedURL(element.powerBiLink, element.tileId)
        );
      }
    });

    //if report is not present in editableHorizontalNavList but present in horizontalNavList then delete
    if (editableHorizontalNavList[report] === undefined) {
      //push in deltedPowerBiLinks
      deletedPowerBiLinks.push(...horizontalNavList[report]);
    } else {
      //if present in horizontalNavList but not in editableHorizontalNavList then delete

      b[report]?.forEach((element: any, index: number) => {
        // check if editableHorizontalNavList has an element with same tileId
        const tile =
          editableHorizontalNavList[report] &&
          editableHorizontalNavList[report].filter(
            (item: any) => item.tileId === element.tileId
          );
        if (tile.length === 0) {
          // delete powerBiLink
          deletedPowerBiLinks.push(element);
        }
      });
    }

    let newHorizontalNavList: any = {};
    temp.forEach((element) => {
      const tmpList = a[element].map((item: any) => {
        //return everything except powerbi link
        const { powerBiLink, ...rest } = item;
        return rest;
      });

      newHorizontalNavList = { ...newHorizontalNavList, [element]: tmpList };
    });

    console.log("new Power Bi Links ", newPowerBiLinks);
    console.log("Updated Power Bi Links ", updatedPowerBiLinks);
    console.log("Deleted Power Bi Links ", deletedPowerBiLinks);

    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "HORIZONTAL_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(newHorizontalNavList)),
        },
        {
          withCredentials: true,
        }
      );
      setHorizontalNavList(editableHorizontalNavList);
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
    if (newPowerBiLinks.length > 0) {
      try {
       await protectedAxiosInstance.post("/api/service/AddReportConfiguration", newPowerBiLinks, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("Error in adding new power bi links", error);
      }
    }
    if (deletedPowerBiLinks.length > 0) {
      console.log("Deleted Power Bi Links ", deletedPowerBiLinks);
      const dataToBeDeleted = deletedPowerBiLinks.map(
        (item: any) => item.tileId
      );
      console.log("Data to be deleted ", dataToBeDeleted);
      try {
      await  protectedAxiosInstance.post("/api/service/DeleteReportConfiguration", dataToBeDeleted, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("Error in deleting power bi links", error);
      }
    }

    if (updatedPowerBiLinks.length > 0) {
      try {
       await protectedAxiosInstance.post(
          "/api/service/UpdateReportConfiguration",
          updatedPowerBiLinks,
          {
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error("Error in updating power bi links", error);
      }
    }

    //setHorizontalNavList(a)
  };

  const handleCancel = () => {
    // setEditableHorizontalNavList(horizontalNavList);
    // setParentContainer('')
    // setPageName('')
    console.log("Horizontal Nav List ", horizontalNavList);

    const temp = JSON.parse(JSON.stringify(horizontalNavList));
    setEditableHorizontalNavList(temp);
    // setReport('')
    console.log("Editable Horizontal Nav List ", editableHorizontalNavList);
    setInEditMode([]);
    setEmptyObjList([]);
    setEditMode("");
  };
  const handleDeleteTab = (tileId: string) => {
    const updatedHorizontalNavList = editableHorizontalNavList[report].filter(
      (item: any) => item.tileId !== tileId
    );

    if (updatedHorizontalNavList.length === 0) {
      const temp = JSON.parse(JSON.stringify(editableHorizontalNavList));
      delete temp[report];
      setEditableHorizontalNavList(temp);
      setShowDialog(true);
      return;
    }
    const temp = {
      ...editableHorizontalNavList,
      [report]: updatedHorizontalNavList,
    };
    setInEditMode(inEditMode.filter((item: any) => item !== tileId));
    setEditableHorizontalNavList(temp);
  };

  const checkReportURLType = (url: string) => {
    const splitUrl = url.split("/").filter((item: string) => item !== "");
    // console.log("Split URL ", splitUrl)
    // if any of the item contains reportEmbed then it is embed url
    if (splitUrl.includes("reportEmbed")) {
      return "EmbedURL";
    }
    return "ReportURL";
  };

  const handleChangeTileText = (e: any, index: number) => {
    let updatedHorizontalNavList = editableHorizontalNavList[report];
    updatedHorizontalNavList[index].tileText = e.target.value;
    updatedHorizontalNavList[index].tileId = `${report}_${e.target.value
      .split(" ")
      .join("")}`;
    const temp = {
      ...editableHorizontalNavList,
      [report]: updatedHorizontalNavList,
    };
    setEditableHorizontalNavList(temp);

    if (
      e.target.value !== "" &&
      updatedHorizontalNavList[index].powerBiLink !== ""
    ) {
      const newEmptyObj = emptyObjList.filter((obj: any) => obj != index);
      setEmptyObjList(newEmptyObj);
    } else {
      //if index not already present add it
      if (emptyObjList.indexOf(index) == -1) {
        setEmptyObjList([...emptyObjList, index]);
      }
    }
  };

  const handleChangeReportUrl = (e: any, index: number) => {
    let updatedHorizontalNavList = editableHorizontalNavList;
    console.log("To be updated ", updatedHorizontalNavList[report][index]);
    updatedHorizontalNavList[report][index].powerBiLink = e.target.value;
    const temp = {
      ...editableHorizontalNavList,
      [report]: updatedHorizontalNavList[report],
    };
    setEditableHorizontalNavList(temp);
    if (
      e.target.value !== "" &&
      updatedHorizontalNavList[report][index].tileText !== ""
    ) {
      const newEmptyObj = emptyObjList.filter((obj: any) => obj != index);
      setEmptyObjList(newEmptyObj);
    } else {
      //if index not already present add it
      if (emptyObjList.indexOf(index) == -1) {
        setEmptyObjList([...emptyObjList, index]);
      }
    }
  };
  const createEmbedURL = (link: string, containerID: string) => {
    //link may or may not have reportsection
    // https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/7334cd75-166f-4e43-9eee-ac99081c89cb/ReportSection3ca4a57200502c6d92eb?experience=power-bi
    //https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/7334cd75-166f-4e43-9eee-ac99081c89cb
  
    // const destructuredReportLink = link.split("/re");
    // const reportId = destructuredReportLink[destructuredReportLink.length - 2];
    // console.log("Report ID ", reportId);
    // const pageID =
    //   destructuredReportLink[destructuredReportLink.length - 1].split("?")[0];
    // // create embed report link
    // const embedReportLink = `https://msit.powerbi.com/reportEmbed?reportId=${reportId}`;
    // const page = pageName.split(" ").join("");
    // const data = {
    //   ReportID: reportId,
    //   ContainerID: containerID,
    //   PowerBIURL: embedReportLink,
    //   SectionName: pageID,
    // };
    // return data;

    const destructuredReportLink = link.split("reports/");
    const reportId = destructuredReportLink[1].split("/")[0];
    const embedReportLink = `https://msit.powerbi.com/reportEmbed?reportId=${reportId}`;
    const ReportSection = destructuredReportLink[1].split("/");
    const pageID = ReportSection.length > 1 ? ReportSection[1].split("?")[0] : "";
    const data = {
      ReportID: reportId,
      ContainerID: containerID,
      PowerBIURL: embedReportLink,
      SectionName: pageID,
    };
    return data;

  };

  const createReportUrl = (reportObject: any) => {
    // const data = {
    //     ReportID: reportId,
    //     ContainerID: parentContainer + '_' + page,
    //     PowerBIURL: embedReportLink,
    //     SectionName: pageID,
    // }
    // Convert report obj to this link // https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/7334cd75-166f-4e43-9eee-ac99081c89cb/ReportSection3ca4a57200502c6d92eb?experience=power-bi
    // report id from "https://msit.powerbi.com/reportEmbed?reportId=f5e81e38-5749-4b70-be8f-44e706dd9a58"
    // console.log("Report Object ", reportObject)
    let reportUrl;
    if (!reportObject) return "";
    let reportId = reportObject.PowerBIURL as string;
    reportId = reportId.split("reportId=")[1];
    console.log("Report ID ", reportId);

    if (
      window.location.href.includes("ftbi") ||
      window.location.href.includes("ftbi.microsoft.com")
    ) {
      if(reportObject.SectionName !== "") {
      reportUrl = `https://msit.powerbi.com/groups/9a95ad97-5af4-4412-8c23-17d789e1170d/reports/${reportId}/${reportObject.SectionName}?experience=power-bi`;
      }
      else {
        reportUrl = `https://msit.powerbi.com/groups/9a95ad97-5af4-4412-8c23-17d789e1170d/reports/${reportId}`;
      }
    } else {
      if(reportObject.SectionName !== "") {
        reportUrl = `https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/${reportId}/${reportObject.SectionName}?experience=power-bi`;
      }
      else {
        reportUrl = `https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/${reportId}`;
      }
      // reportUrl = `https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/${reportId}/${reportObject.SectionName}?experience=power-bi`;
    }
    return reportUrl;
  };
  const setLevelwiseTileData = (navList: any) => {
    let tempSecondLevelData: any = {},
      tempThirdLevelData: any = {};

    navList.forEach((item: any, index: number) => {
      // if(index === 0) return
      if (index > 0) {
        if (item.hasSubPages) {
          // map subPages to the tileId
          tempSecondLevelData[item.tileId] = item.subPages;
          // map third level data to the subPages
          item.subPages.forEach((subPage: any) => {
            if (subPage.hasSubPages) {
              tempThirdLevelData[subPage.tileId] = subPage.subPages;
            } else {
              tempThirdLevelData[subPage.tileId] = [];
            }
          });
        } else {
          tempSecondLevelData[item.tileId] = [];
        }
      }
    });
    // console.log("Second Level Data ", tempSecondLevelData)
    setSecondLevelData(tempSecondLevelData);
    setThirdLevelData(tempThirdLevelData);
  };

  const fetchNavData = async (tempPowerBiLinks: any) => {
    let tmpnavList, horizontalTileList1: any, horizontalTileList2: any;
    try {
      const response = await protectedAxiosInstance.get("/api/service/GetNavlist", {
        withCredentials: true,
      });
      tmpnavList = JSON.parse(JSON.parse(response.data.Table[0].ListData));
      setNavList(tmpnavList);
      setEditableNavList(tmpnavList);
      setLevelwiseTileData(tmpnavList);
    } catch (error) {
      console.error("Error", error);
    }
    try {
      const response = await protectedAxiosInstance.get("/api/service/GetHorizontalTileList", {
        withCredentials: true,
      });
      horizontalTileList1 = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      horizontalTileList2 = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      console.log("Horizontal Tile List ", horizontalTileList1);
      let temp = Object.keys(horizontalTileList1);
      temp.forEach((item: any) => {
        horizontalTileList1[item].forEach((element: any) => {
          const Uid = Math.floor(Math.random() * 1000);
          const powerBiLink = tempPowerBiLinks.filter(
            (link: any) => link.ContainerID === element.tileId
          );
          if (powerBiLink.length > 0) {
            element.powerBiLink = createReportUrl(powerBiLink[0]);
          }
          element.Uid = Uid;
        });
        horizontalTileList2[item].forEach((element: any) => {
          const Uid = Math.floor(Math.random() * 1000);
          const powerBiLink = tempPowerBiLinks.filter(
            (link: any) => link.ContainerID === element.tileId
          );
          if (powerBiLink.length > 0) {
            element.powerBiLink = createReportUrl(powerBiLink[0]);
          }
          element.Uid = Uid;
        });
      });
      setEditableHorizontalNavList(
        horizontalTileList1
      );
      setHorizontalNavList(horizontalTileList2);
      // console.log("Horizontal Tile List in fetch data ", horizontalTileList)
    } catch (error) {
      console.error("Error", error);
    }
  };

  const addNewTab = (index?: number) => {
    // const page = tileText.split(' ').join('')
    const newTab = {
      Uid: Math.floor(Math.random() * 1000),
      tileId: `new_${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: false,
      subPages: [],
      powerBiLink: "",
    };
    const updatedHorizontalNavList = editableHorizontalNavList[report];
    // add newTab at index+1 in horizontalNavList[report]
    if (!editableHorizontalNavList[report]) {
      const temp = { ...editableHorizontalNavList, [report]: [newTab] };
      setEditableHorizontalNavList(temp);
      return;
    }
    if (index === undefined) {
      updatedHorizontalNavList.unshift(newTab);
      setEmptyObjList([...emptyObjList, 0]);
    } else {
      updatedHorizontalNavList.splice(index + 1, 0, newTab);
      setEmptyObjList([...emptyObjList, index + 1]);
    }
    const temp = {
      ...editableHorizontalNavList,
      [report]: updatedHorizontalNavList,
    };
    setEditableHorizontalNavList(temp);
  };

  useEffect(() => {
    const powerBiConfig = localStorage.getItem("powerbi_config");
    if (powerBiConfig) {
      const config = JSON.parse(powerBiConfig);
      // console.log("Config ", config.Table)
      setPowerBiLinks(config.Table);
      fetchNavData(config.Table);
    } else {
      // TODO: fetch power bi config from /api/service/GetDataWithConfiguration
    }
  }, []);
  return (
    <div className="containerList">
      <div className="horizontalDropdownContainer">
        {navList && (
          <div className="selectTabComponent">
            <div className="selectTabText">Select Section</div>
            <AdminSelectComponent
              onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                console.log("Selected Value ", e.target.value);
                setParentContainer(e.target.value);
                setReport("");
                setPageName("");
                setFirstSelectValue(e.target.value);
                setSecondSelectValue("select");
                setEditableHorizontalNavList(
                  JSON.parse(JSON.stringify(horizontalNavList))
                );
              }}
              title="Select Section"
              options={navList}
              selectedValue={firstSelectValue}
            />
          </div>
        )}
        {parentContainer !== "" &&
          secondLevelData[parentContainer]?.length > 0 && (
            <div className="selectTabComponent childSelectTabComponent">
              <div className="selectTabText">Select Report/Subsection</div>
              <AdminSelectComponent
                onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (thirdLevelData[e.target.value].length > 0) {
                    console.log(
                      "Selected Value ka h third level data",
                      e.target.value
                    );
                    setPageName(e.target.value);
                    setReport("");
                  } else {
                    const idxOfParent = navList.findIndex(
                      (item: any) => item.tileId === parentContainer
                    );
                    console.log(
                      "Index of Parent ",
                      idxOfParent,
                      parentContainer,
                      navList
                    );

                    const idx = navList[idxOfParent].subPages.findIndex(
                      (item: any) => item.tileId === e.target.value
                    );
                    console.log("Index ", idx);
                    if (!navList[idxOfParent].subPages[idx].hasSubPages) {
                      console.log("Selected Value", e.target.value);
                      setReport(e.target.value);
                      setPageName("");
                    }
                  }
                  setSecondSelectValue(e.target.value);
                  setThirdSelectValue("select");
                  setEditableHorizontalNavList(
                    JSON.parse(JSON.stringify(horizontalNavList))
                  );
                  setInEditMode([]);
                }}
                title="Select Report/Subsection"
                options={secondLevelData[parentContainer]}
                selectedValue={secondSelectValue}
              />
            </div>
          )}
        {pageName !== "" && thirdLevelData[pageName]?.length > 0 && (
          <div className="selectTabComponent childSelectTabComponent">
            <div className="selectTabText">Select Report</div>
            <AdminSelectComponent
              onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                console.log("Selected Value", e.target.value);
                setReport(e.target.value);
                setThirdSelectValue(e.target.value);
                setEditableHorizontalNavList(
                  JSON.parse(JSON.stringify(horizontalNavList))
                );
                setInEditMode([]);
              }}
              title="Select Report"
              options={thirdLevelData[pageName]}
              selectedValue={thirdSelectValue}
            />
          </div>
        )}
      </div>

      {report !== "" &&
        (editableHorizontalNavList[report] ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="HorizontalListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="HorizontalListTableHeaderTileText">Name</th>
                    <th className="HorizontalListTableHeaderLink">
                      Report URL
                    </th>
                    <th className="HorizontalListTableHeaderActions">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="containerBody">
                  {editableHorizontalNavList[report].map(
                    (item: any, index: number) => {
                      return (
                        <AdminItem
                          item={item}
                          index={index}
                          inEditMode={inEditMode}
                          emptyObj={emptyObjList}
                          setEmptyObj={(value: any) => setEmptyObjList(value)}
                          setEditModeTab={(tab: string) => setEditMode(tab)}
                          setEditMode={(value: any) => setInEditMode(value)}
                          handleDeleteTab={handleDeleteTab}
                          addNewTab={addNewTab}
                          handleChangeTileText={handleChangeTileText}
                          handleChangeReportUrl={handleChangeReportUrl}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("horizontalList");
                addNewTab();
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={emptyObjList.length > 0 || editModeTab === "NavList" || editModeTab === ""}
                style={{
                    backgroundColor: `${
                        (emptyObjList.length > 0 || (editModeTab === "NavList" || editModeTab === ""))  ? "#f5f3f3" : "#0078d4"
                    }`,
                    color: `${(emptyObjList.length > 0 || (editModeTab === "NavList" || editModeTab === "")) ? "Black" : "White"}`,
                  }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <PowerBiLinkedTab
            report={report}
            powerBiConfig={powerBiLinks}
            addNewTab={addNewTab}
            updateHorizontalNavList={updateHorizontalNavList}
            setEditModeTab={(tab: string) => setEditMode(tab)}
            createEmbedURL={(link: string, tileId: string) =>
              createEmbedURL(link, tileId)
            }
            setPowerBiConfig={(obj: any) => setPowerBiLinks(obj)}
            createReportUrl={(obj: any) => createReportUrl(obj)}
          />
        ))}
      {showdialog && (
        <AdminDialogueBox
          message="Are you sure you want to save the changes?"
          buttonText="Save"
          saveChanges={updateHorizontalNavList}
          setShowDialogue={(value: boolean) => setShowDialog(value)}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default AdminHorizontalTile;
