import React, { useEffect, useState } from "react";
import AddNewNavItemDialogueBox from "./AddNewNavItemDialogueBox";

interface props {
  item: any;
  index: number;
  parentTab: string;
  inEditMode: string[];
  emptyObj: any[];
  setEmptyObj: (value: any) => void;
  setEditModeTab: (value: string) => void;
  setEditMode: (value: any) => void;
  handleDelete: (index: number, isFolder: boolean) => void;
  handleAddNewTab: (index: number) => void;
  handleAddNewFolder: (index: number) => void;
  handleChangeTileText: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  isSubsectionAdditionAllowed?: boolean;
}

const AdminNavListItem: React.FC<props> = ({
  item,
  index,
  parentTab,
  inEditMode,
  emptyObj,
  setEmptyObj,
  setEditModeTab,
  setEditMode,
  handleDelete,
  handleChangeTileText,
  handleAddNewTab,
  handleAddNewFolder,
  isSubsectionAdditionAllowed,
}) => {
  const [addNewTab, setAddNewTab] = useState(false);
  const [editTab, setEditTab] = useState(true);

  const setInEditMode = () => {
    // console.log("Index",inEditMode.indexOf(item.tileId), inEditMode, item.tileId)
    if (inEditMode.indexOf(item.tileId) == -1) {
      // console.log("In Edit Mode", inEditMode, item.tileId)
      setEditMode([...inEditMode, item.tileId]);
    }
  };

  const deleteFromEmptyObj = (index: number) => {
    const newEmptyObj = emptyObj.filter((obj) => obj != index);
    setEmptyObj(newEmptyObj);
  };


  useEffect(() => {
    console.log("Edit Mode change hua", inEditMode);
    if (inEditMode.length > 0) {
      let check = false;
      inEditMode.map((id: string) => {
        if (id == item.tileId) {
          setEditTab(false);
          check = true;
        }
      });

      if (!check) {
        setEditTab(true);
      }
    } else {
      setEditTab(true);
    }
  }, [inEditMode, item]);

  useEffect(() => {
    console.log("item change hua", item.tileId, inEditMode);
    console.log("Item", item);
    if (item.tileText === ""){
      if(inEditMode.indexOf(item.tileId) == -1){
        setEditMode([...inEditMode, item.tileId]);
      }
    }
    if (inEditMode.length > 0)
      inEditMode.map((id: string) => {
        if (id == item.tileId) setEditTab(false);
      });
  }, [item]);

  return (
    <tr className="navListTableRow">
      <td className="navListTableRowTileText">
        <input
          className={`${editTab ? "inputStyle" : "inputStyleEdit"}`}
          disabled={editTab}
          onChange={(e) => handleChangeTileText(e, index)}
          name="tileText"
          type="text"
          value={item.tileText}
        />{" "}
      </td>
      <td className="navListTableRowType">
        {item.hasSubPages ? "Subsection" : "Report"}
      </td>
      <td className="navListTableRowActions">
        <div className="actions">
          <div
            className="actionsComp"
            onClick={() => {
              setEditModeTab("navList");
              deleteFromEmptyObj(index);
              handleDelete(index, item.hasSubPages);
            }}
            title="Delete this item"
          >
            <i
              className="ms-Icon ms-Icon--Delete iconStyle"
              aria-hidden="true"
            ></i>
            <span className="actionsText">Delete</span>
          </div>
          <div
            className="actionsComp"
            onClick={() => {
              setEditModeTab("navList");
              setInEditMode();
            }}
            title="Edit this item"
          >
            <i
              className="ms-Icon ms-Icon--Edit iconStyle"
              aria-hidden="true"
            ></i>
            <span className="actionsText">Edit</span>
          </div>
          <div
            className="actionsComp"
            onClick={() => {
              setEditModeTab("navList");
              setAddNewTab(true);
            }}
            title="Add new item below this"
          >
            <i
              className="ms-Icon ms-Icon--Add iconStyle"
              aria-hidden="true"
            ></i>
            <span className="actionsText">Add</span>
          </div>
        </div>
      </td>
      {addNewTab && (
        <AddNewNavItemDialogueBox
          addNewReport={() => handleAddNewTab(index)}
          addNewFolder={() => handleAddNewFolder(index)}
          setShowDialogue={(value) => setAddNewTab(value)}
          message="This new item will be added below the selected one. Select the type of item to add:"
          isSubsectionAdditionAllowed={isSubsectionAdditionAllowed}
        />
      )}
    </tr>
  );
};

AdminNavListItem.defaultProps = {
  isSubsectionAdditionAllowed: true,
}

export default AdminNavListItem;
