import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminDialogueBox from "./AdminDialogueBox";
import AdminNavListItem from "./AdminNavListItem";
import AddNewNavItemDialogueBox from "./AddNewNavItemDialogueBox";
import protectedAxiosInstance from "./helpers/api";
import AdminSelectComponent from "./AdminSelectComponent";


interface props {
  editModeTab: string;
  setEditMode: (value: string) => void;
}

const AdminNavList: React.FC<props> = ({ editModeTab, setEditMode }) => {
  const [navList, setNavList] = useState<any>([]);
  const [horizontalNavList, setHorizontalNavList] = useState<any>();
  const [editableHorizontalNavList, setEditableHorizontalNavList] = useState<any>();
  const [dataList, setDataList] = useState<any>({});
  const [addNewTab, setAddNewTab] = useState<boolean>(false);
  const [newParentTabText, setNewParentTabText] = useState<any>("");
  const [tableView, setTableView] = useState<any>("");
  const [intitialDataList, setInitialDataList] = useState<any>({});
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteParentTab, setShowDeleteParentTab] = useState<boolean>(false);
  const [selectedParentTab, setSelectedParentTab] = useState<any>("");
  const [inEditMode, setInEditMode] = useState<any>([]);
  const [showAddToTop, setShowAddToTop] = useState<boolean>(false);
  const [newParentTileId, setNewParentTileId] = useState<any>("");
  const [emptyObjList, setEmptyObjList] = useState<any>([]);
  const [secondSelectValue, setSecondSelectValue] = useState<string>("select");  
  const [secondLevelData, setSecondLevelData] = useState<any>([]);
  const [thirdLevelData, setThirdLevelData] = useState<any>([]);
  const [isSubsectionAdditionAllowed, setIsSubsectionAdditionAllowed] = useState<boolean>(true);

  const handleChangeTileText = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // console.log(
    //   "DataListBeforeChange",
    //   dataList[tableView].subPages[index].tileText
    // );
    const tmpDataList = { ...dataList };
    tmpDataList[tableView].subPages[index].tileText = e.target.value;
    // if(tmpDataList[tmpDataList[tableView].subPages[index].tileId] != undefined) {
    //     tmpDataList[tmpDataList[tableView].subPages[index].tileId].tileText = e.target.value
    // }
    setDataList(tmpDataList);
    console.log(
      "DataListAfterChange",
      tmpDataList[tableView].subPages[index].tileText
    );

    if (e.target.value === "") {
      //if index not already present add it
      if (emptyObjList.indexOf(index) == -1) {
        setEmptyObjList([...emptyObjList, index]);
      }
    } else {
      //if index is present delete it
      if (emptyObjList.indexOf(index) != -1) {
        const newEmptyObj = emptyObjList.filter((obj: number) => obj != index);
        setEmptyObjList(newEmptyObj);
      }
    }
  };

  const handleDelete = (index: number, isFolder: boolean) => {
    console.log(
      "DataListBeforeChange",
      dataList[tableView].subPages[index].tileText
    );
    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    const tileId = tmpDataList[tableView].subPages[index].tileId;
    tmpDataList[tableView].subPages.splice(index, 1);
    if (!isFolder) {
      handleDeleteReport(index);
    } else {
      handleDeleteFolder(index);
    }
    setDataList(tmpDataList);
    setInEditMode(inEditMode.filter((item: any) => item !== tileId));
    if (index === 0 && tmpDataList[tableView].subPages.length === 0) {
      setShowDialog(true);
    }
  };

  const handleDeleteReport = (index: number) => {
    // console.log("Before EditableHorizontalNavList", editableHorizontalNavList, typeof editableHorizontalNavList)
    const updatedHorizontalNavList = { ...editableHorizontalNavList };
    // console.log("DataListBeforeChange", updatedHorizontalNavList[dataList[tableView].subPages[index].tileId])
    delete updatedHorizontalNavList[
      dataList[tableView].subPages[index].tileId
    ];
    // console.log("After EditableHorizontalNavList", updatedHorizontalNavList)
    setEditableHorizontalNavList(updatedHorizontalNavList);
  };

  const handleDeleteFolder = (index: number) => {
    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    tmpDataList[tableView].subPages[index].subPages.forEach(
      (item: any) => {
        const updatedHorizontalNavList = { ...editableHorizontalNavList };
        delete updatedHorizontalNavList[item.tileId];
        setEditableHorizontalNavList(updatedHorizontalNavList);
      }
    );
  };

  const handleAddNewTab = (index?: number) => {
    // console.log("DataListBeforeChange", dataList[tableView].subPages[index].tileText)
    const tmpDataList = { ...dataList };
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: false,
      subPages: [],
    };
    if (index === undefined) {
      tmpDataList[tableView].subPages.unshift(newTab);
      setEmptyObjList([...emptyObjList, 0]);
    } else {
      tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
      setEmptyObjList([...emptyObjList, index + 1]);
    }
    // tmpDataList[tableView].subPages.splice(index + 1, 0, newTab)
    setDataList(tmpDataList);
    // console.log("DataListAfterChange", tmpDataList[tableView].subPages[index].tileText )
  };

  const handleAddNewFolder = (index?: number) => {
    // console.log("DataListBeforeChange", dataList[tableView].subPages[index].tileText)
    const tmpDataList = { ...dataList };
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: true,
      subPages: [],
    };
    if (index === undefined) {
      tmpDataList[tableView].subPages.unshift(newTab);
      setEmptyObjList([...emptyObjList, 0]);
    } else {
      tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
      setEmptyObjList([...emptyObjList, index + 1]);
    }
    setDataList(tmpDataList);
    // console.log("DataListAfterChange", tmpDataList[tableView].subPages[index].tileText )
  };

  const handleAddNewParentTab = () => {
    //add new parent tab to dataList
    const tmpDataList = { ...dataList };
    const newParentTab = {
      tileId: createTileId(newParentTabText),
      tileText: newParentTabText,
      hasSubPages: true,
      subPages: [],
    };
    tmpDataList[newParentTab.tileId] = newParentTab;
    console.log("New Parent Tab", newParentTab);
    console.log("DataList", tmpDataList);
    setDataList(tmpDataList);
  };

  const handleCancel = () => {
    const tmpHorizontalList = JSON.parse(JSON.stringify(horizontalNavList));
    const tmpDataList = JSON.parse(JSON.stringify(intitialDataList));
    setDataList(tmpDataList);
    setEditableHorizontalNavList(tmpHorizontalList);
    setInEditMode([]);
    setEmptyObjList([]);
    setEditMode("");
  };

  const handleCancelNewParentTab = () => {
    setAddNewTab(false);
    setNewParentTabText("");
  };

  const createTileId = (tileText: string) => {
    //create unique tileId from tileText
    const text = tileText.replace(/\s/g, "");
    //take first 3 characters and last 3 characters
    const tileId =
      text.substring(0, 3) +
      text.substring(text.length - 3) +
      Math.floor(Math.random() * 1000);
    setNewParentTileId(tileId);
    return tileId;
  };

  const updateNavList = async (navList: any) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "NAV_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(navList)),
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const updateHorizontalNavList = async (tmpHorizontalList: any) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "HORIZONTAL_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(tmpHorizontalList)),
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const handleDeleteParentTab = async () => {
    //selectedParentTab is delted from navList
    const tmpDataList = { ...dataList };
    delete tmpDataList[selectedParentTab];

    const tmpNavList = JSON.parse(JSON.stringify(navList));
    tmpNavList.forEach((item: any) => {
      console.log("Item in delte Parent Tab", item.tileId, item.subPages);
      if (item?.tileId === selectedParentTab) {
        const idx = tmpNavList.indexOf(item);
        console.log("Index", idx);
        tmpNavList.splice(tmpNavList.indexOf(item), 1);

        navList[idx].subPages.forEach((subpage: any) => {
          if (subpage.hasSubPages) {
            subpage.subPages.forEach((report: any) => {
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              console.log(
                "UpdatedHorizontalNavList after change",
                updatedHorizontalNavList
              );
              console.log("In second if");
              updateHorizontalNavList(updatedHorizontalNavList);
            });
          } else {
            const updatedHorizontalNavList = { ...editableHorizontalNavList };
            delete updatedHorizontalNavList[subpage.tileId];
            console.log(
              "UpdatedHorizontalNavList after change",
              updatedHorizontalNavList,
              subpage.tileId
            );
            console.log("In first else");
            updateHorizontalNavList(updatedHorizontalNavList);
          }
        });
      } else {
        console.log("In second else");
        item?.subPages.forEach((subpage: any) => {
          // console.log("Subpage in second else", subpage)
          if (  subpage.tileId === selectedParentTab) {
            item.subPages.splice(item.subPages.indexOf(subpage), 1);
            console.log("In third if");
            subpage.subPages.forEach((report: any) => {
              console.log(
                "Before EditableHorizontalNavList",
                editableHorizontalNavList
              );
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              console.log(
                "After EditableHorizontalNavList",
                updatedHorizontalNavList
              );
              updateHorizontalNavList(updatedHorizontalNavList);
              // setEditableHorizontalNavList(updatedHorizontalNavList)
            });
          }
        });
      }
    });

    //delete from horizontalNavList

    // setDataList(tmpDataList)
    updateNavList(tmpNavList);

    setSelectedParentTab("");
  };

  const handleSave = async () => {
    //check if new tab is added
    const tmpDataList = { ...dataList };
    const tmpNavList = [...navList];
    console.log("TmpDataList", tmpDataList);
    console.log("SelectedParentTab", selectedParentTab);
    console.log("TmpNavList", tmpNavList);
    // disabling this buggy. Code code will go directly to else block
    if ( tableView !== "") {
      tmpDataList[tableView]?.subPages.forEach((item: any) => {
        if (item.tileId.includes("-new-")) {
          item.tileId = createTileId(item.tileText);
        }
      });

      //find selectedParentTab in navList and update it

      tmpNavList.forEach((item: any) => {
        if (item != null) {
          if (item.tileId === tableView) {
            item.subPages = tmpDataList[tableView].subPages;
          } else {
            item.subPages.forEach((subpage: any) => {
              if (subpage.tileId === tableView) {
                subpage.subPages = tmpDataList[tableView].subPages;
              }
            });
          }
        }
      });

      console.log("TmpNavList", tmpNavList);
      updateHorizontalNavList(editableHorizontalNavList);
    } else {
      //new parent tab is added
      //find new parent tab in navList and update it
      console.log("New Parent Tile Id", newParentTileId);
      const newParentTab = tmpDataList[newParentTileId];
      tmpNavList.push(newParentTab);
      console.log("New Parent Tab", newParentTab);
    }

    console.log("NavList", tmpNavList);

    updateNavList(tmpNavList);
  };

  const setLevelwiseTileData = (navList: any) => {
    let tempSecondLevelData: any = {},
      tempThirdLevelData: any = {};

    navList.forEach((item: any, index: number) => {
      // if(index === 0) return
      if (index > 0) {
        if (item.hasSubPages) {
          // map subPages to the tileId
          tempSecondLevelData[item.tileId] = item.subPages;
          // map third level data to the subPages
          item.subPages.forEach((subPage: any) => {
            if (subPage.hasSubPages) {
              tempThirdLevelData[subPage.tileId] = subPage.subPages;
            } else {
              tempThirdLevelData[subPage.tileId] = [];
            }
          });
        } else {
          tempSecondLevelData[item.tileId] = [];
        }
      }
    });
    // console.log("Second Level Data ", tempSecondLevelData)
    setSecondLevelData(tempSecondLevelData);
    setThirdLevelData(tempThirdLevelData);
  };

  const fetchNavList = async () => {
    try {
      const response = await protectedAxiosInstance.get("/api/service/GetNavlist", {
        withCredentials: true,
      });
      const tmpnavList = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      setLevelwiseTileData(tmpnavList);
      setNavList(JSON.parse(JSON.parse(response.data.Table[0].ListData)));
      // traverse tmpnavList and get keys for which hasSubpages is true
      let tmpDataList: any = {};
      tmpnavList.forEach((item: any) => {
        console.log("Item", item);
        if (item != null && item.hasSubPages) {
          tmpDataList[item.tileId] = item;
          console.log("Subpages", tmpDataList[item.tileId]);
          item.subPages.length > 0 &&
            item.subPages.forEach((subpage: any) => {
              if (subpage.hasSubPages) {
                tmpDataList[subpage.tileId] = subpage;
                console.log("Subpages", tmpDataList[subpage.tileId]);
              }
            });
        }
      });
      let tmpDataList2 = JSON.parse(JSON.stringify(tmpDataList));
      setInitialDataList(tmpDataList2);
      setDataList(tmpDataList);
      console.log("TmpNavList", tmpDataList);
    } catch (error) {
      console.error("Error", error);
    }

    try {
      const response = await protectedAxiosInstance.get("/api/service/GetHorizontalTileList", {
        withCredentials: true,
      });

      const tmpHorizontalNavList1 = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      const tmpHorizontalNavList2 = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );

      setHorizontalNavList(tmpHorizontalNavList1);
      setEditableHorizontalNavList(tmpHorizontalNavList2);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    fetchNavList();
    // const tmpNavList = JSON.stringify(JSON.stringify(Constants.NAV_TILE_LIST_NON_PROD.home))
    // updateNavList(tmpNavList)
    // console.log("NavList", tmpNavList)
  }, []);

  return (
    <div className="containerList">
      {dataList && (
        <div className="NavBarDropdowns" style={{display: "flex", alignItems: "flex-end", flexWrap: "wrap"}}>
            {navList && (
              <div className="selectTabComponent">
                <div className="selectTabText">Select Section</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSelectedParentTab(()=>e.target.value);
                    setSecondSelectValue("select");
                    // setReport("");
                    // setPageName("");
                    setEditableHorizontalNavList(
                      JSON.parse(JSON.stringify(horizontalNavList))
                    );
                  }}
                  title="Select Section"
                  options={navList}
                  selectedValue={selectedParentTab}
                />
              </div>
            )}

            {tableView !== "" && secondLevelData[selectedParentTab]?.length > 0 && (
              <div className="selectTabComponent childSelectTabComponent">
                <div className="selectTabText">Select Subsection</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSecondSelectValue(e.target.value);
                    setIsSubsectionAdditionAllowed(e.target.value === selectedParentTab ? true : false);
                  }}
                  parentSection={selectedParentTab}
                  title="Select Report/Subsection"
                  options={secondLevelData[selectedParentTab]}
                  selectedValue={secondSelectValue}
                  ignoreSubpages={true}
                />
              </div>
            )}

            <div onClick={() => setAddNewTab(true)} className="addParentTab">
              <i
                className="ms-Icon ms-Icon--Add addParentTabIcon"
                aria-hidden="true"
              ></i>
              <span className="addParentTabText">Add New Section</span>
            </div>

            {selectedParentTab && (
              <div
                onClick={() => {
                  setShowDeleteParentTab(true);
                }}
                className="deleteParentTab"
              >
                <i
                  className="ms-Icon ms-Icon--Delete deleteParentTabIcon"
                  aria-hidden="true"
                ></i>
                <span className="deleteParentTabText">Delete Section</span>
              </div>
            )}
        </div>
      )}

      {addNewTab && (
        <div className="addNewParentTabContainer">
          <input
            className="inputNewParentStyle"
            value={newParentTabText}
            onChange={(e) => setNewParentTabText(e.target.value)}
            type="text"
            placeholder="Enter Section Name"
          />
          <button
            disabled={newParentTabText.length === 0 || newParentTabText === " "}
            style={{
              backgroundColor: `${
                newParentTabText.length !== 0 ? "#0078d4" : "#f5f3f3"
              }`,
              color: `${newParentTabText.length !== 0 ? "White" : "Black"}`,
            }}
            onClick={() => {
              handleAddNewParentTab();
              setShowDialog(true);
            }}
          >
            Add
          </button>
          <button
            onClick={() => {
              handleCancelNewParentTab();
            }}
          >
            Cancel
          </button>
        </div>
      )}

      {selectedParentTab &&
        (dataList[selectedParentTab].subPages.length > 0 ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="navListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="navListTableHeaderTileText">Name</th>
                    <th className="navListTableHeaderType">Type</th>
                    <th className="navListTableHeaderActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList[tableView].subPages.map(
                    (item: any, index: number) => {
                      return (
                        <AdminNavListItem
                          item={item}
                          index={index}
                          parentTab={selectedParentTab}
                          inEditMode={inEditMode}
                          emptyObj={emptyObjList}
                          setEmptyObj={(value: any) => setEmptyObjList(value)}
                          setEditModeTab={(value: string) => setEditMode(value)}
                          setEditMode={(value: any) => setInEditMode(value)}
                          handleDelete={handleDelete}
                          handleAddNewTab={handleAddNewTab}
                          handleAddNewFolder={handleAddNewFolder}
                          handleChangeTileText={handleChangeTileText}
                          isSubsectionAdditionAllowed={isSubsectionAdditionAllowed}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("navList");
                setShowAddToTop(true);
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={
                  emptyObjList.length > 0 ||
                  editModeTab === "horizontalList" ||
                  editModeTab === ""
                }
                style={{
                  backgroundColor: `${
                    emptyObjList.length > 0 ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "#f5f3f3"
                      : "#0078d4"
                  }`,
                  color: `${
                    emptyObjList.length > 0 ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "Black"
                      : "White"
                  }`,
                }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              height: "80px",
            }}
          >
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewTab(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Report</span>
            </div>
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewFolder(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Subsection</span>
            </div>
          </div>
        ))}
      {showDialog && (
        <AdminDialogueBox
          message="Are you sure you want to save the changes?"
          buttonText="Save"
          saveChanges={handleSave}
          setShowDialogue={(value: boolean) => setShowDialog(value)}
          onCancel={handleCancel}
        />
      )}
      {showDeleteParentTab && (
        <AdminDialogueBox
          message="Are you sure you want to delete the Section? This action cannot be undone."
          buttonText="Delete"
          saveChanges={handleDeleteParentTab}
          setShowDialogue={(value: boolean) => setShowDeleteParentTab(value)}
          onCancel={handleCancel}
        />
      )}
      {showAddToTop && (
        <AddNewNavItemDialogueBox
          addNewReport={() => handleAddNewTab()}
          addNewFolder={() => handleAddNewFolder()}
          setShowDialogue={(value) => setShowAddToTop(value)}
          message="This new item will be added to the top of the selected Section/Subsection. Select the type of item to add:"
        />
      )}
    </div>
  );
};

export default AdminNavList;
