import React from 'react'

interface props {
    addNewReport: () => void;
    addNewFolder: (showDialogue: boolean) => void;
    setShowDialogue: (showDialogue: boolean) => void;
    message: string;
    isSubsectionAdditionAllowed?: boolean;
}

const AddNewNavItemDialogueBox: React.FC<props> = ({addNewReport,addNewFolder,setShowDialogue,message, isSubsectionAdditionAllowed}) => {
    const handleAddReport = () => {
        addNewReport()
        setShowDialogue(false)
    }
    const handleAddFolder = () => {
        addNewFolder(false)
        setShowDialogue(false)
    }

  return (
    <div className="unsaved-changes-dialog">
            {<div className="unsaved-changes-dialog__content" >
                <i className="ms-Icon ms-Icon--Cancel cancle" style={{cursor: "pointer"}} aria-hidden="true" onClick={() => setShowDialogue(false)}></i>
                <div className='Dialogue_text'
                >
                    <div style={{lineHeight: "36px", fontFamily: "'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif", fontSize: "24px" }}>
                    {message}
                    </div>
                    {/* <div style={{fontFamily:"'Segoe UI Semilight',wf_segoe-ui_semilight,helvetica,arial,sans-serif",color:"#333333", fontSize:"14px", marginTop:"20px",marginBottom:"20px"}}>
                Would you like to save changes to this report?
                </div> */}
                </div>
                <div className="unsaved-changes-dialog__buttons">
                    <button className="save " onClick={handleAddReport}>Report</button>
                    {isSubsectionAdditionAllowed && <button className="save" onClick={handleAddFolder}>Subsection</button>}
                </div>

            </div>}
        </div>
  )
}

AddNewNavItemDialogueBox.defaultProps = {
    isSubsectionAdditionAllowed: true
}

export default AddNewNavItemDialogueBox