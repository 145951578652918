import React, { useEffect, useState } from 'react'
import DataRefresh from './DataRefresh'
import hat from '../Images/hat_1.svg'
import support from '../Images/Support_1.svg'
import uservoice from '../Images/UserVoice_1.svg'
import user from "../Images/NoProfileUser.png"
import axios from 'axios'
import { signOut } from './helpers/Common'
import { useNavigate } from 'react-router-dom'



const RightSection = () => {
    const [showUserInformation, setShowUserInformation] = useState(false);
    const [userImage, setUserImage] = useState<string>(user)
    const userDetails = localStorage.getItem("user")
    const userName = userDetails ? JSON.parse(userDetails).displayName : ""
    const userEmail = userDetails ? JSON.parse(userDetails).mail : ""
    const navigate = useNavigate();
    const fetchUserImage = (accessToken: any) => {
        let imgUrl;
        axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: 'blob'
        }).then((response) => {
            if (response !== undefined)
                imgUrl = window.URL.createObjectURL(response.data);
            else
                imgUrl = user
            setUserImage(imgUrl)
        }).catch((result) => {
            console.error("Error", result)
        })
    }
    
    const showHideUserInformation = () => {
        setShowUserInformation(!showUserInformation)
        // also hide the user information box when clicked outside
        document.addEventListener('click', function (event) {
            var userInformationBox = document.getElementById('UserInformationBox');
            var userImage = document.getElementsByClassName('UserImageStyle');
            if (event.target !== userInformationBox && event.target !== userImage[0]) {
                setShowUserInformation(false)
            }
        });
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') !== null)
            fetchUserImage(localStorage.getItem('accessToken'))
    }, [])

    return <div className="HeaderRightSection">

        <DataRefresh />

        <div className="Settings" onClick ={() =>{navigate("../Pages/Training")}}>
            <img className="pull-left trainingicon" src={hat} width="22" height="22" alt="Settings" title="Training" />
        </div>

        <div className="Settings">
            <a href="https://aka.ms/FTReportFeedback" target="_blank"><img className="pull-left trainingicon" src={support} width="22" height="22" alt="Support" title="Support" /></a>
        </div>
        <div className="Settings">
            <a><img className="pull-left trainingicon" src={uservoice} width="22" height="22" alt="User Voice" title="User Voice" /></a>
        </div>

        <div className="Settings" id="rightborder">
            <img className="UserImageStyle" src={userImage} width="22" height="22" alt="User Image" title="Profile"
                onClick={() => {
                    showHideUserInformation()
                }}
            />
            {showUserInformation && <div>
                <div className="UserInformationArrow"> </div>
                <div className="UserInformation">
                    <div className="UserInformationArrow"></div>
                    <div className="UserInformationBox" id='UserInformationBox'>
                        <div className="UserNameList ellipsis">{userName}</div>
                        <div className="UserMailList ellipsis" id="UserMailList">{userEmail}</div>
                        <div className="EntryPoints">
                            <div className="usersection signout" onClick={async () => {
                                await signOut()
                            }}>Sign Out</div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
}

export default RightSection