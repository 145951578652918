
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface props {
  loggedInUser: string;
  userImg: string;
}

const Header: React.FC<props>= ({loggedInUser, userImg}) => {
  return (
    <Container className="my-4 headerdiv" >
      <Row>
        <Col>
          <div className="d-flex profilepicture " >
            <div className="rounded-circle text-white p-3">
            <img src={userImg} alt={loggedInUser} style={{ height: '75px', borderRadius: '50%' ,marginTop: '-15px'}} />
            </div>
            <div className="ms-3">
              <h2>Welcome to FastTrack BI</h2>
              <p>{loggedInUser}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
