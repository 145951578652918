import React, { useState } from 'react'

interface props {
    onChangeEffect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    title: string;
    options: any[];
    selectedValue: string;
    ignoreSubpages?: boolean;
    parentSection?: string;
}

const AdminSelectComponent: React.FC<props> = ({onChangeEffect,title,options,selectedValue,ignoreSubpages = false, parentSection}) => {
  return (
    <select value={selectedValue ? selectedValue : "select"}  onChange={(e) => onChangeEffect(e)}
        title={title}
        className="selectTabDropdown"
    >
        {/* <option className="selectTabOptions" value={item.tileId}>{item.tileText}</option>; */}

        <option key="select" style={{ color: "#464444" }} value="select" disabled hidden>-Select-</option>
        {parentSection && <option key="Subsection Parent" className="selectTabOptions" style={{fontStyle: "italic"}} value={parentSection}>Subsection Parent</option>}
        {
            options.map((item: any, index: number) => {
                if (item.tileText === "Landing Page") {
                    return null;
                }
                if (ignoreSubpages && !item.hasSubPages){
                    return null;
                }
                return <option key={item.tileId} className="selectTabOptions" value={item.tileId}>{item.tileText}</option>;
            })
        }
    </select>
  )
}
AdminSelectComponent.defaultProps = {
    ignoreSubpages: false,
};

export default AdminSelectComponent
